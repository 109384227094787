import React, { FC, useEffect, useLayoutEffect, useState } from 'react';
import { IProductItem } from '@/Models/Product/IProduct';
import { StyledProductArticle } from './style';
import { BASE_URL } from '@/Services/Axios/Config';
import { HiLocationMarker } from 'react-icons/hi';
import numberService from '@/Services/NumberService';
import { asyncCalculateDistance, toKmM } from '@/Services/Coordinates';
import Link from 'next/link';
import { ProductType } from '@/Models/Product/IProduct';
import CustomImage from '@/Components/atoms/Image';
import { useTranslation } from 'next-i18next';
import { LIST_GRID_TYPE } from '@/Types/CommonTypes';
import { getAspectRatio } from '@/Utils/getAspectRatio';
import CommonSvg, { svgNames } from '@/Components/atoms/CommonSvg';
import FavoriteApi from '@/Services/Axios/Controllers/FavoriteApi';
import { useIsAuth } from '@/Hooks/useIsAuth';
import { useContextSelector } from 'use-context-selector';
import { AuthContext } from '@/Context/Auth';
import { getImageUrlByPath } from '@/Services/Image';
import { getProductDetailUrl } from '@/Utils/getProductDetailUrl';
import { DateService } from '@/Services/DateService';
import DiamondSvg from '@/Components/atoms/CommonSvg/diamondSvg';
import TopSvg from '@/Components/atoms/CommonSvg/topSvg';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { FreeMode, Thumbs } from 'swiper';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/navigation';
import 'swiper/css/thumbs';
import { IMyRegion } from '@/Models/Region/IRegion';
import RegionApi from '@/Services/Axios/Controllers/RegionApi';
import ProductApi from '@/Services/Axios/Controllers/ProductApi';
import { logger } from '@/Services/Logger';

interface IProductItemProps {
  item: IProductItem;
  deferImage: boolean;
  quality?: number;
  ADS?: boolean;
  type?: LIST_GRID_TYPE;
  onRemoveFavorite?: (id: number) => void;
}

interface IThumbnailImg {
  id: string;
  image_path: string;
}

type ThumbsSwiperType = SwiperCore | null;

const ProductItem: FC<IProductItemProps> = ({ item, deferImage, quality = 75, ADS, type = LIST_GRID_TYPE.MASONRY, onRemoveFavorite }) => {
  const isAuth = useIsAuth();
  const showAuthModal = useContextSelector(AuthContext, v => v.actions.showModal);
  const [calculatedDistance, setCalculatedDistance] = React.useState<string>('0');
  const [isFavoriteServer, setIsFavoriteServer] = React.useState(!!item.favoriteId);
  const [isFavorite, setIsFavorite] = React.useState(!!item.favoriteId);
  const { t, i18n } = useTranslation(['common']);
  const currentLang = i18n.language as 'uz' | 'ru';
  // const detailPath = `/item/${ item.name.trim().replaceAll(' ', '-') + '-' + item.id}`;
  const detailPath = item.slug ? `/item/${item.slug}` : getProductDetailUrl(item.name, item.id);

  const _distance = item.destination ?? item.distance ?? null;
  const distance_from_backend_by_saved_user_coords = _distance && toKmM(_distance);
  const [thumbsSwiper, setThumbsSwiper] = useState<ThumbsSwiperType>(null);
  const [thumbnailImg, setThumbnailImg] = useState<IThumbnailImg[]>([]);
  // NOTE - less than 24 hour ==>> 'Today 12:54'
  // NOTE - 24 hour < time > 48 hour ==>> 'Yesterday 12:35'
  // NOTE - OTHERS ==>> '28-April'
  const [myRegions, setMyRegions] = React.useState({} as IMyRegion);

  const toggleFavorite = () => {
    if (!isAuth) {
      showAuthModal();
      return;
    }
    setIsFavorite(p => !p);
  };

  useEffect(() => {
    asyncCalculateDistance(+item.lat, +item.lan).then(res => {
      setCalculatedDistance(res.toFixed(1));
    });
  }, []);

  useEffect(() => {
    // RegionApi.getRegionByLangCode({ lat: item.lat, lon: item.lan, lang: currentLang }).then(res => {
    //   setMyRegions(res.data);
    // });
  }, [currentLang, item]);

  useEffect(() => {
    const idTimeout = setTimeout(() => {
      // NOTE: agar boshlang'ich holat bilan keyingi holat teng bo'lmasa request ketadi
      if (isFavorite !== isFavoriteServer) {
        FavoriteApi.toggleFavorite(item.id).then(res => {
          if ((res.data.message as string).includes('Remove')) {
            setIsFavorite(false);
            setIsFavoriteServer(false);
            onRemoveFavorite && onRemoveFavorite(item.id);
            item.favoriteId = null;
          } else {
            setIsFavorite(true);
            setIsFavoriteServer(true);
            item.favoriteId = 1;
          }
        });
      }
    }, 500);

    return () => {
      clearTimeout(idTimeout);
    };
  }, [isFavorite]);

  const onHoverFunc = (id: number) => {
    ProductApi.getImagesThumbnail(id).then(res => {
      setThumbnailImg(res.data);
      logger.log(res.data);
    });
  };

  const onLeaveHoverFunc = () => {
    ProductApi.getImagesThumbnail(0).then(res => {
      setThumbnailImg(res.data);
      logger.log(res.data);
    });
  };

  return (
    <StyledProductArticle listStyle={type}>
      <div
        // onMouseEnter={() => onHoverFunc(item.id)}
        className={'product-item ' + (item.type === ProductType.START ? 'start' : item.type === ProductType.PREMIUM ? 'premium' : '')}
      >
        <Link href={detailPath} className='product-item__img-link'>
          {thumbnailImg.length > 0 ? (
            <Swiper loop={true} spaceBetween={10} navigation={false} thumbs={{ swiper: thumbsSwiper }} modules={[FreeMode, Thumbs]} className='mySwiper2'>
              {thumbnailImg.map((t, index) => {
                return (
                  <SwiperSlide key={index}>
                    <CustomImage
                      style={{
                        aspectRatio: getAspectRatio(item.imageMode),
                      }}
                      quality={quality}
                      priority={deferImage}
                      src={getImageUrlByPath(t.image_path, 200, 200)}
                      alt={t.id}
                      className={`product-item__img ${item.imageMode}`}
                    />
                  </SwiperSlide>
                );
              })}
            </Swiper>
          ) : (
            <CustomImage
              quality={quality}
              priority={deferImage}
              width={200}
              height={200}
              src={getImageUrlByPath(item.imagePath, 200, 200)}
              alt={item.name}
              className={`product-item__img ${item.imageMode}`}
              style={{
                aspectRatio: getAspectRatio(item.imageMode),
              }}
            />
          )}
        </Link>

        <div className='product-item__content'>
          {thumbnailImg.length > 0 ? (
            <div className='swiper-imgs'>
              <Swiper
                style={{ marginTop: 10 }}
                onSwiper={setThumbsSwiper}
                loop={true}
                spaceBetween={10}
                slidesPerView={4}
                freeMode={true}
                watchSlidesProgress={true}
                modules={[FreeMode, Thumbs]}
                className='mySwiper'
              >
                {thumbnailImg.map((t, index) => {
                  return (
                    <SwiperSlide key={index}>
                      <CustomImage quality={quality} priority={deferImage} src={getImageUrlByPath(t.image_path, 200, 200)} alt={t.id} className={`product-item__img`} style={{}} />
                    </SwiperSlide>
                  );
                })}
              </Swiper>
            </div>
          ) : (
            <div>
              <span className='product-item__state'>{item.state === 'NEW' ? t('newest') : item.state === 'SECOND_HAND' ? t('used') : ''}</span>
              <h3 className='product-item__title'>
                <Link title={item.name} href={detailPath}>
                  {item.name}
                </Link>
              </h3>
              {type === LIST_GRID_TYPE.HORIZONTAL ? <p className='product-item__descr'>{item.description}</p> : <></>}

              {type !== LIST_GRID_TYPE.HORIZONTAL ? (
                <p className='product-item__location'>
                  {item.lat && item.lan && myRegions?.region} <span>•</span> {!!(item.lat && item.lan) && <span className='product-item__distance'>{toKmM(+calculatedDistance * 1000)}</span>}
                </p>
              ) : (
                <></>
              )}
              {type !== LIST_GRID_TYPE.HORIZONTAL ? <hr /> : <></>}
            </div>
          )}

          {/*  */}

          {type !== LIST_GRID_TYPE.HORIZONTAL ? (
            <div className='product-item__bottom'>
              <p className='product-item__price'>
                {numberService.getPrice(item.price)} {item.currencyType === 'UZS' ? t('som') : t('ye')}
              </p>
              <span className='product-item__time'>{DateService.toDDMMYYYYHHMM(item.createdAt)}</span>
            </div>
          ) : (
            <div className='product-item__bottom'>
              <p className='product-item__price'>
                {numberService.getPrice(item.price)} {item.currencyType === 'UZS' ? t('som') : t('ye')}
              </p>
              <p className='product-item__location'>
                {item.lat && item.lan && myRegions?.region} <span>•</span> {!!(item.lat && item.lan) && <span className='product-item__distance'>{toKmM(+calculatedDistance * 1000)}</span>}
              </p>
            </div>
          )}
        </div>

        {/* ---------------- ABSOLUTE PLACED BUTTONS ---------------- */}
        <button className={`product-item__heart ` + (isFavorite ? 'product-item__heart--active' : '')} onClick={toggleFavorite}>
          <CommonSvg name={svgNames.Heart} />
        </button>

        <button className='product-item__type-start'>
          <TopSvg /> Top
        </button>
        <button className='product-item__type-premium'>
          <DiamondSvg /> Premium
        </button>
        {/* ---------------- END ABSOLUTE PLACED BUTTONS ---------------- */}
      </div>
    </StyledProductArticle>
  );
};

// export default React.memo(ProductItem);
export { default } from './NewProductItem';

// if(ADS) {
//   return (
//     <>
//       <StyledProductArticle>
//         <div className={'product-item ' + (item.type === ProductType.START ? 'start' : item.type === ProductType.PREMIUM ? 'premium' : '')}>
//         <div dangerouslySetInnerHTML={{ __html: `<script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-2682687091464442"
//                 crossorigin="anonymous"></script>
//           <!-- ITEM -->
//           <ins class="adsbygoogle"
//                 style="display:block"
//                 data-ad-client="ca-pub-2682687091464442"
//                 data-ad-slot="5371771554"
//                 data-ad-format="auto"
//                 data-full-width-responsive="true"></ins>
//           <script>
//                 (adsbygoogle = window.adsbygoogle || []).push({});
//           </script>` }}/>
//         </div>
//         <CustomImage
//           priority={deferImage}
//           width={200}
//           height={400}
//           src={`${BASE_URL}/mobile/${item.imageUrl}`}
//           alt={item.name}
//           className={`product-item__img ${item.imageMode}`}
//           style={{ display: 'none' }}
//         />
//       </StyledProductArticle>
//     </>
//   )
// }
