import { IMAGE_MODE } from "@/Models/Product/IProduct";


export const getAspectRatio = (imageType: IMAGE_MODE): string => {
  
  switch (imageType) {
    case IMAGE_MODE.LANDSCAPE: {
      return '4 / 3';
    }
    case IMAGE_MODE.PORTRAIT: {
      return '3 / 4';
    }
    case IMAGE_MODE.SQUARE: {
      return '1 / 1';
    }
    default: {
      return '1 / 1';
    }
  }
}