import { LIST_GRID_TYPE } from '@/Types/CommonTypes';
import { colors } from '@/styles/colors';
import { sizes } from '@/styles/sizes';
import { styled, css } from '@mui/material/styles';

const StyledProductArticle = styled('article')<{ listStyle?: LIST_GRID_TYPE }>`
  .product-item {
    border-radius: 0.75rem;
    /* overflow: hidden; */
    position: relative;
    background-color: #fff;
    padding: 6px;
    border: 1px solid rgba(0, 0, 0, 0.2);
    transition: 0.3s ease-in-out;
    &:hover {
      box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.2);
    }

    .swiper-imgs {
      img {
        height: 100%;
        width: 100%;
        object-fit: cover;
      }
    }

    hr {
      color: red;
    }

    &__state {
      color: #0c88c6;
      font-size: 12px;
      font-weight: 500;
    }

    &__type-start,
    &__type-premium {
      position: absolute;
      top: -9px;
      left: -9px;
      height: 30px;
      padding: 0 15px;
      border-radius: 6px;

      /* text-transform: uppercase; */
      letter-spacing: 1px;
      font-weight: 400;
      font-size: 12px;
      color: #fff;
      outline: none;
      border: none;
      cursor: pointer;

      display: none; // when visible display should be flex
      align-items: center;
      justify-content: center;
    }

    &__heart {
      position: absolute;
      top: 0;
      right: 0;
      width: 44px;
      height: 44px;
      border-radius: 0 0 0 12px;
      background-color: transparent;
      outline: none;
      border: none;
      cursor: pointer;

      display: flex;
      align-items: center;
      justify-content: center;
      ${props =>
        props.listStyle === LIST_GRID_TYPE.HORIZONTAL &&
        css`
          top: 0px !important;
          left: 0;
          background-color: transparent !important;
        `}

      svg {
        color: transparent;
        stroke: #fff;
      }

      &:hover {
        opacity: 0.8;
      }
      &:active {
        opacity: 0.6;
      }

      &--active {
        svg {
          color: red;
        }
      }
    }

    &__img {
      max-width: 100%;
      width: 100% !important;
      height: auto;

      ${props =>
        !(props.listStyle === LIST_GRID_TYPE.MASONRY || props.listStyle === LIST_GRID_TYPE.GRID) &&
        css`
          height: 100%;
          max-height: 10.5rem;
          min-width: 10.5rem;
          width: auto;
        `}

      ${props =>
        props.listStyle === LIST_GRID_TYPE.HORIZONTAL &&
        css`
          width: auto;
          max-height: 12.5rem;
        `}

      object-position: center;
      object-fit: cover;
      cursor: pointer;
      display: block;
      border-radius: 0.75rem;
    }

    &__content {
      padding: 8px 12px;
    }

    &__title {
      font-size: ${props => props.theme.customTheme.sizes.small};
      font-weight: 400;
      line-height: 1.4;
      display: block;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      display: -webkit-box;
      overflow: hidden;
      text-overflow: ellipsis;

      margin-top: 0;
      margin-bottom: 0.5em;
      & > a {
        cursor: pointer;
        color: #000;
        text-decoration: none;
      }
    }

    &__location {
      display: flex;
      color: #818181;
      font-size: 10px;
      font-weight: 400;
    }

    &__descr {
      font-size: ${props => props.theme.customTheme.sizes.normal};
      font-weight: 400;
      font-size: 12px;
      color: #8b8b8b;
      line-height: 19px;
      white-space: nowrap;
      display: block;

      // NOTE 1 qator uchun
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &__price {
      font-size: ${props => props.theme.customTheme.sizes.small};
      font-weight: 600;
      line-height: 1;
      color: ${props => props.theme.customTheme.colors.dark};

      margin-top: 0;
      margin-bottom: 0;
      white-space: nowrap;
      /* margin-bottom: 0.75em; */
    }

    &__bottom {
      display: flex;
      align-items: center;
      justify-content: space-between;

      font-size: ${props => props.theme.customTheme.sizes.xSmall};
      line-height: ${props => props.theme.customTheme.sizes.small};
      letter-spacing: -0.3px;
    }

    &__distance {
      color: #818181;
      display: flex;
      align-items: center;
      line-height: 1;
      font-size: 10px;
    }

    &__time {
      color: ${props => props.theme.customTheme.colors.primary};
      font-size: 12px;
    }
  }

  .product-item.premium,
  .product-item.start {
    .product-item__title {
      color: #000;
      & > a {
        color: #000 !important;
      }
    }

    .product-item__descr {
      color: #000 !important;
    }
    .product-item__price {
      color: #000 !important;
    }
    .product-item__distance {
      color: #818181 !important;
    }
    /* background-color: ${props => props.theme.customTheme.bg.orange}; */
  }

  .product-item.premium {
    border: 1px solid ${props => props.theme.customTheme.bg.pink20};
    &:hover {
      box-shadow: 0px 4px 4px 0px rgba(195, 17, 98, 0.2);
    }
    .product-item__type-premium {
      display: flex;
      gap: 10px;
      color: #fff;
      border: 1px solid ${props => props.theme.customTheme.bg.pink};
      background-color: ${props => props.theme.customTheme.bg.pink};
    }
    .product-item__state {
      color: ${colors.pink};
      font-size: 12px;
    }

    .product-item__img {
      /* border: 2px solid ${props => props.theme.customTheme.bg.orange}; */
    }
  }

  .product-item.start {
    border: 1px solid ${props => props.theme.customTheme.bg.primary20};
    &:hover {
      box-shadow: 0px 4px 4px 0px rgba(12, 136, 198, 0.2);
    }
    .product-item__type-start {
      display: flex;

      background-color: ${props => props.theme.customTheme.bg.primary};
    }
    .product-item__img {
    }
  }

  // ------------------------------------ GRID STYLE CHANGE ------------------------------------
  ${props =>
    props.listStyle === LIST_GRID_TYPE.GRID
      ? css`
          .product-item {
            &__title {
              min-height: calc(1.4 * 2 * ${sizes.normal});
            }
          }
        `
      : ''}

  ${props =>
    props.listStyle === LIST_GRID_TYPE.MASONRY || props.listStyle === LIST_GRID_TYPE.GRID
      ? ''
      : css`
          margin-bottom: 30px;

          .product-item {
            display: flex;
            width: 100%;

            &__img-link {
              flex-shrink: 0;
            }

            &__content {
              flex-grow: 1;
              /* padding: 1.35rem; */
              display: flex;
              flex-direction: column;
              justify-content: space-between;
              column-gap: 2rem;
            }

            &__bottom {
              display: flex !important;
              align-items: center;
              justify-content: space-between;
            }
            &__distance {
              color: #818181;
              font-size: ${props.theme.customTheme.sizes.normal};
              line-height: 1.5;
            }
            &__price {
              color: #0c88c6;
              font-size: 20px;
              font-weight: 600;
            }
            &__location {
              font-size: 14px;
              display: flex;
              gap: 10px;
              align-items: center;
            }
            &__state {
              font-size: 14px;
              margin-bottom: 10px;
            }
            &__title {
              margin-top: 10px;
              margin-bottom: 0.5rem;
              font-size: ${props.theme.customTheme.sizes.small};
              font-style: normal;
              font-weight: 600;
              line-height: 150%;
              white-space: normal;
            }
            &__descr {
              margin: 0;
              font-size: ${props.theme.customTheme.sizes.normal};
              font-weight: 400;
              margin: 10px 0 22px;
              font-size: 1rem;
              color: #8b8b8b;
              line-height: 22px;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
              display: -webkit-box;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: normal;
            }

            &__heart {
              background-color: green;
              top: 0.625rem;
              right: 0.625rem;
              border-radius: 0.9375rem;
              background-color: #eee;
            }
          }

          .product-item.premium,
          .product-item.start {
            background-color: #fff;

            .product-item__title {
              a {
                color: #000;
              }
            }

            .product-item__distance {
              color: #818181;
            }
            .product-item__descr {
              color: ${colors.suvaGrey};
            }
          }

          .product-item.premium {
            .product-item__img {
              /* border: 2px solid ${colors.orange}; */
            }
            .product-item__price {
              color: ${colors.dark};
            }
          }
          .product-item.start {
            .product-item__img {
              /* border: 2px solid ${colors.primary}; */
            }
            .product-item__price {
              color: ${colors.primary};
            }
          }
        `}
  ${props =>
    props.listStyle === LIST_GRID_TYPE.MASONRY
      ? ''
      : css`
          .product-item.premium {
            .product-item__img {
              /* border: 2px solid ${props.theme.customTheme.bg.orange}; */
            }

            .product-item__title {
              a {
                color: #000;
              }
            }

            .product-item__distance {
              color: #818181;
            }
            .product-item__price {
              color: rgba(7, 143, 205, 255);
            }
            .product-item__descr {
              color: #8b8b8b;
            }
          }
        `}
  ${props =>
    props.listStyle === LIST_GRID_TYPE.HORIZONTAL
      ? ''
      : css`
          .product-item.premium {
            .product-item__img {
              /* border: 2px solid ${props.theme.customTheme.bg.orange}; */
            }

            .product-item__title {
              a {
                color: #fff;
              }
            }

            .product-item__distance {
              color: #fff;
            }
            .product-item__price {
              color: #fff;
            }
            .product-item__descr {
              color: #fff;
            }
          }
        `}
  


  @media (max-width: 767.98px) {
    ${props =>
      props.listStyle === LIST_GRID_TYPE.MASONRY || props.listStyle === LIST_GRID_TYPE.GRID
        ? ''
        : css`
            margin-bottom: 15px;

            .product-item {
              /* &__title {
                width: 80%;
              } */
              &__img {
                max-height: 10rem;
                max-width: 10rem;
                min-height: 10rem;
              }
              &__content {
                padding: 20px !important;
                width: 80%;
                display: flex;
                flex-direction: column;
              }
              &__bottom {
                width: 100%;
                display: flex;
                align-items: center;
                justify-content: space-between;
                flex-direction: row-reverse;
              }
              &__descr {
                -webkit-line-clamp: 2;
                margin-bottom: 0.5rem;
              }
            }
          `}

    ${props =>
      props.listStyle === LIST_GRID_TYPE.MASONRY || props.listStyle === LIST_GRID_TYPE.HORIZONTAL
        ? ''
        : css`
            .product-item {
              &__title {
                min-height: calc(1.4 * 2 * ${sizes.small});
              }
            }
          `}
    .product-item {
      &__title {
        font-size: ${props => props.theme.customTheme.sizes.small};
        line-height: 1.4;
      }

      &__price {
        font-size: ${props => props.theme.customTheme.sizes.normal};
        line-height: ${props => props.theme.customTheme.sizes.middle};
      }

      &__distance {
        font-size: ${props => props.theme.customTheme.sizes.xSmall};
      }
      &__time {
        font-size: ${props => props.theme.customTheme.sizes.xSmall};
        display: none;
      }
    }
  }
`;

const ListSkeleton = styled('div')`
  display: flex;
  width: 100%;
  .wrap {
    width: 50%;
    display: flex;
    gap: 20px;
    align-items: center;
  }
  .left {
    width: 10rem;
    span {
      width: 100%;
    }
  }
  .right {
    width: calc(100% - 10rem);
  }

  @media (max-width: 991px) {
    flex-direction: column;
    .wrap {
      width: 100%;
    }
  }
`;

export { StyledProductArticle, ListSkeleton };
