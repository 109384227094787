import apiService from "../ApiService";

async function getFavorites() {
  return await apiService
    .setUrl(`/favorites?favoriteId=10000000&size=10000000`)
    .setMethod("GET")
    .request();
};

async function toggleFavorite(id: number) {
  return await apiService
    .setUrl(`/favorites/${id}`)
    .setMethod("GET")
    .request();
};


const FavoriteApi = {
  getFavorites,
  toggleFavorite
}

export default FavoriteApi;
