

function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0');
}

function toDDMMYYYY(date: Date | string): string {
  if(typeof date === 'string') {
    date = new Date(date);
    return [
      padTo2Digits(date.getDate()),
      padTo2Digits(date.getMonth() + 1),
      date.getFullYear(),
    ].join('.');
  }

  return [
    padTo2Digits(date.getDate()),
    padTo2Digits(date.getMonth() + 1),
    date.getFullYear(),
  ].join('.');
}

function toHHMM(date: Date | string): string {
  if(typeof date === 'string') {
    date = new Date(date);
    return [
      date.getHours(),
      padTo2Digits(date.getMinutes()),
    ].join(':');
  }

  return [
    date.getHours(),
    padTo2Digits(date.getMinutes()),
  ].join(':');
}

function toDDMMYYYYHHMM(date: Date | string): string {
  if (typeof date === 'string') {
    date = new Date(date);
  }

  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'short' });
  const hours = padTo2Digits(date.getHours());
  const minutes = padTo2Digits(date.getMinutes());

  return `${day}-${month}, ${hours}:${minutes}`;
}



export const DateService = {
  toDDMMYYYY,
  toHHMM,
  toDDMMYYYYHHMM
};
