import { Box, Divider, IconButton, Stack, Typography } from '@mui/material';
import React, { memo, useEffect, useMemo, useState } from 'react';
import { StyledProductItem, StyledHorizontalProductItem } from './NewProductItem.style';
import { LIST_GRID_TYPE } from '@/Types/CommonTypes';
import { IProductItem, ProductType, PRODUCT_STATE } from '@/Models/Product/IProduct';
import { useIsAuth } from '@/Hooks/useIsAuth';
import { useContextSelector } from 'use-context-selector';
import { AuthContext } from '@/Context/Auth';
import { useTranslation } from 'next-i18next';
import { getProductDetailUrl } from '@/Utils/getProductDetailUrl';
import { toKmM } from '@/Services/Coordinates';
import FavoriteApi from '@/Services/Axios/Controllers/FavoriteApi';
import CustomImage from '@/Components/atoms/Image';
import { getImageUrlByPath } from '@/Services/Image';
import { getAspectRatio } from '@/Utils/getAspectRatio';
import DiamondSvg from '@/Components/atoms/CommonSvg/diamondSvg';
import TopSvg from '@/Components/atoms/CommonSvg/topSvg';
import CommonSvg, { svgNames } from '@/Components/atoms/CommonSvg';
import numberService from '@/Services/NumberService';
import BaseButton from '@/Components/atoms/BaseComponents/BaseButton';
import { useRouter } from 'next/router';
import { DateService } from '@/Services/DateService';
import { useIsMobile } from '@/Hooks/useIsMobile';
import MyLink from '@/Components/atoms/MyLink';
import { pageLinks } from '@/Data/pageLinks';

interface IProductItemProps {
  item: IProductItem;
  deferImage: boolean;
  quality?: number;
  type?: LIST_GRID_TYPE;
  onRemoveFavorite?: (id: number) => void;
}

const NewProductItem = ({ item, onRemoveFavorite, ...props }: IProductItemProps) => {
  const router = useRouter();
  const isMobile = useIsMobile();
  const [hovered, setHovered] = useState(false);
  const [visibleImgIndex, setVisibleImgIndex] = useState(0);
  const isAuth = useIsAuth();
  const showAuthModal = useContextSelector(AuthContext, v => v.actions.showModal);
  const [isFavoriteServer, setIsFavoriteServer] = React.useState(!!item.favoriteId);
  const [isFavorite, setIsFavorite] = React.useState(!!item.favoriteId);
  //@ts-ignore
  const { t, i18n } = useTranslation(['common']);
  const currentLang = i18n.language as 'uz' | 'ru';

  const detailPath = item.slug ? `/item/${item.slug}` : getProductDetailUrl(item.name, item.id);
  const _distance = item.destination ?? item.distance ?? null;
  const distance_from_backend_by_saved_user_coords = _distance && toKmM(_distance);
  const allImages = useMemo(() => {
    if (item.thumbnails) {
      return [
        getImageUrlByPath(item.imagePath, 200, 200),
        ...item.thumbnails
          .filter(i => !i.is_main)
          .slice(0, 4)
          .map(imgItem => {
            return getImageUrlByPath(imgItem.image_path, 200, 200);
          }),
      ];
    } else {
      return [getImageUrlByPath(item.imagePath, 200, 200)];
    }
  }, []);

  const toggleFavorite = () => {
    if (!isAuth) {
      showAuthModal();
      return;
    }
    setIsFavorite(p => !p);
  };

  const openChat = () => {
    if (isAuth) {
      router.push(pageLinks.checkAndOpenChat(item.id));
    } else {
      showAuthModal();
    }
  };

  const onHoverImg = (index: number) => {
    setVisibleImgIndex(index);
  };

  const onClickHandler: React.MouseEventHandler<HTMLAnchorElement> = event => {
    if (
      event.currentTarget.classList.contains('product-item__offer-btn') ||
      (event.target as HTMLElement).closest('.product-item__offer-btn')
    ) {
      event.preventDefault();
      openChat();
    }
    if (
      event.currentTarget.classList.contains('product-item__like-btn') ||
      (event.target as HTMLElement).closest('.product-item__like-btn')
    ) {
      event.preventDefault();
    }
  };

  useEffect(() => {
    const idTimeout = setTimeout(() => {
      // NOTE: agar boshlang'ich holat bilan keyingi holat teng bo'lmasa request ketadi
      if (isFavorite !== isFavoriteServer) {
        FavoriteApi.toggleFavorite(item.id).then(res => {
          if ((res.data.message as string).includes('Remove')) {
            setIsFavorite(false);
            setIsFavoriteServer(false);
            onRemoveFavorite && onRemoveFavorite(item.id);
            item.favoriteId = null;
          } else {
            setIsFavorite(true);
            setIsFavoriteServer(true);
            item.favoriteId = 1;
          }
        });
      }
    }, 500);

    return () => {
      clearTimeout(idTimeout);
    };
  }, [isFavorite]);

  if (props.type === LIST_GRID_TYPE.HORIZONTAL) {
    return (
      <StyledHorizontalProductItem
        href={detailPath}
        onClick={onClickHandler}
        data-item-type={item.type}
        className='product-item'
        onMouseEnter={() => setHovered(true)}
      >
        <Box className='product-item__img-wrapper'>
          <CustomImage
            src={allImages[visibleImgIndex]}
            alt={item.name}
            className={`product-item__img`}
            wrapperClassName='product-item__main-img-wrapper'
          />
          <IconButton className='product-item__like-btn' onClick={toggleFavorite}>
            <CommonSvg className='product-item__like-svg' isActive={isFavorite} name={svgNames.NewHeart} />
          </IconButton>
        </Box>

        <Box className='product-item__text-content-wrapper'>
          <Box mb={'auto'} minHeight={150}>
            {(item.type === ProductType.PREMIUM || item.type === ProductType.START) && (
              <Stack spacing={1} justifyContent={'center'} alignItems={'center'} direction={'row'} className='product-item__boost'>
                {item.type === ProductType.PREMIUM ? <DiamondSvg /> : <TopSvg />}
                <Typography variant='body2' className='product-item__boost-text'>
                  {item.type === ProductType.PREMIUM ? 'Premium' : 'Top'}
                </Typography>
              </Stack>
            )}
            <Typography className='product-item__name'>{item.name}</Typography>
            <Typography className='product-item__description'>{isMobile ? '' : item.description}</Typography>
            <Typography className='product-item__location'>
              <Box sx={isMobile ? { width: '50%' } : { width: '75%' }}>
                {item.regionName && item.regionName} {item.districtName && ', ' + item.districtName} /
                {' ' + distance_from_backend_by_saved_user_coords}
              </Box>
              <Box>{DateService.toDDMMYYYYHHMM(item.createdAt)}</Box>
            </Typography>
          </Box>
          <Divider sx={{ backgroundColor: 'rgba(0, 0, 0, 0.1)', borderColor: 'rgba(242, 244, 249, 1)', mb: 0.75, marginTop: 'auto' }} />
          <Stack className='product-item__price-wrapper'>
            <Typography className='product-item__actual-price'>
              {numberService.getPrice(item.price)} {item.currencyType === 'UZS' ? t('som') : t('ye')}
            </Typography>
          </Stack>
        </Box>

        {hovered && !isMobile && (
          <HorizontalHoverState
            visibleImgIndex={visibleImgIndex}
            onHoverImg={onHoverImg}
            allImages={allImages}
            item={item}
            openChat={openChat}
          />
        )}
      </StyledHorizontalProductItem>
    );
  }

  console.log(item);
  

  return (
    <StyledProductItem
      component={MyLink}
      href={detailPath}
      onClick={onClickHandler}
      data-item-type={item.type}
      className='product-item'
      onMouseEnter={() => setHovered(true)}
    >
      <Box mb={1} className='product-item__img-wrapper'>
        {item.thumbnails?.length > 0 ? (
          <CustomImage
            src={allImages[visibleImgIndex]}
            alt={item.name}
            className={`product-item__img ${item.imageMode}`}
            style={{
              aspectRatio: getAspectRatio(item.imageMode),
            }}
          />
        ) : (
          <CustomImage
            src={'/static/images/not-image.png'}
            alt={item.name}
            className={`product-item__img ${item.imageMode}`}
            style={{
              aspectRatio: getAspectRatio(item.imageMode),
            }}
          />
        )}
        <IconButton className='product-item__like-btn' onClick={toggleFavorite}>
          <CommonSvg className='product-item__like-svg' isActive={isFavorite} name={svgNames.NewHeart} />
        </IconButton>
      </Box>
      <Box className='product-item__text-wrapper'>
        <Typography mb={0.75} className='product-item__name'>
          {item.name}
        </Typography>
        {!isMobile && (
          <Typography mb={0.75} className='product-item__location-name'>
            {item.regionName && item.regionName} {item.districtName && ', ' + item.districtName}
          </Typography>
        )}
        {!isMobile && (
          <Typography mb={0.75} className='product-item__location'>
            <div>{distance_from_backend_by_saved_user_coords}</div>
            <div>{DateService.toDDMMYYYYHHMM(item.createdAt)}</div>
          </Typography>
        )}
      </Box>

      <Divider sx={{ backgroundColor: 'rgba(0, 0, 0, 0.1)', mb: 0.75, marginTop: 'auto', borderColor: 'rgba(242, 244, 249, 1)' }} />

      <Box className='product-item__price-wrapper'>
        {/* <Typography mb={0.5} className='product-item__old-price'>
          {numberService.insertSpaceAndFix(item.price, 2)} {item.currencyType === 'UZS' ? t('som') : t('ye')}
        </Typography> */}
        <Stack direction={'row'} justifyContent={'end'} alignItems={'center'}>
          <Typography mb={0.75} className='product-item__actual-price'>
            {numberService.getPrice(item.price)} {item.currencyType === 'UZS' ? t('som') : t('ye')}
          </Typography>
        </Stack>
      </Box>

      {/* ----------------------- ABSOLUTE ELEMENTS --------------------------- */}
      {(item.type === ProductType.PREMIUM || item.type === ProductType.START) && (
        <Stack spacing={1} justifyContent={'center'} alignItems={'center'} direction={'row'} className='product-item__boost'>
          {item.type === ProductType.PREMIUM ? <DiamondSvg /> : <TopSvg />}
          <Typography variant='body2' className='product-item__boost-text'>
            {item.type === ProductType.PREMIUM ? 'Premium' : 'Top'}
          </Typography>
        </Stack>
      )}

      {/* -------------- */}
      {hovered && !isMobile && (
        <GridHoverState visibleImgIndex={visibleImgIndex} onHoverImg={onHoverImg} allImages={allImages} item={item} openChat={openChat} />
      )}
    </StyledProductItem>
  );
};

interface IHoverStateProps {
  allImages: string[];
  visibleImgIndex: number;
  item: IProductItem;
  openChat: () => void;
  onHoverImg: (idx: number) => void;
}

const GridHoverState = ({ allImages, item, openChat, onHoverImg, visibleImgIndex }: IHoverStateProps) => {
  //@ts-ignore
  const { t } = useTranslation(['common']);

  return (
    <Box className='product-item__hover-screen'>
      <Box
        // mb={1.25}
        sx={{
          position: 'relative',
        }}
      >
        {item.thumbnails?.length > 0 ? (
          <CustomImage
            src={allImages[visibleImgIndex]}
            alt={item.name}
            className={`product-item__hover-screen-img ${item.imageMode}`}
            style={{
              aspectRatio: getAspectRatio(item.imageMode),
            }}
          />
        ) : (
          <CustomImage
            src={'/static/images/not-image.png'}
            alt={item.name}
            className={`product-item__hover-screen-img ${item.imageMode}`}
            style={{
              aspectRatio: getAspectRatio(item.imageMode),
            }}
          />
        )}
      </Box>
      <Box className='product-item__hover-screen-text'>
        <Stack
          direction={'row'}
          spacing={0.75}
          className='product-item__other-images'
          mb={1}
          sx={{
            animation: 'open-opacity 0.3s ease',
          }}
        >
          {
            item.thumbnails?.length > 0 ? 
            allImages.map((img, index) => {
              return (
                <CustomImage
                  key={img}
                  src={img}
                  onMouseEnter={() => onHoverImg(index)}
                  alt=''
                  wrapperClassName='product-item__other-img-wrapper'
                  style={{
                    aspectRatio: getAspectRatio(item.imageMode),
                  }}
                />
              );
            }) : <></>
          }
        </Stack>
        <Typography
          sx={{
            animation: 'open-opacity 0.3s ease',
          }}
          mb={1}
          className='product-item__actual-price'
        >
          {numberService.getPrice(item.price)} {item.currencyType === 'UZS' ? t('som') : t('ye')}
        </Typography>
        <BaseButton
          sx={{
            animation: 'open-opacity 0.3s ease',
          }}
          className='product-item__offer-btn'
          onClick={openChat}
        >
          <CommonSvg name={svgNames.Handshake} />
          {t('offerNow')}
        </BaseButton>
      </Box>
    </Box>
  );
};

const HorizontalHoverState = ({ allImages, item, openChat, onHoverImg, visibleImgIndex }: IHoverStateProps) => {
  const { t } = useTranslation(['common']);

  return (
    <Box className='product-item-hover'>
      <Box className='product-item__img-wrapper'>
        <CustomImage
          src={allImages[visibleImgIndex]}
          alt={item.name}
          className={'product-item__img'}
          wrapperClassName='product-item__main-img-wrapper'
        />
      </Box>

      <Box className='product-item__text-content-wrapper'>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'flex-end'} sx={{ marginBottom: '30px' }}>
          <Typography className='product-item-hover__actual-price' mb={'auto'}>
            {numberService.getPrice(item.price)} {item.currencyType === 'UZS' ? t('som') : t('ye')}
          </Typography>
          <BaseButton
            sx={{
              animation: 'open-opacity 0.3s ease',
            }}
            className='product-item-hover__offer-btn'
            onClick={openChat}
          >
            <CommonSvg name={svgNames.Handshake} />
            Offer now
          </BaseButton>
        </Stack>
        <Stack direction={'row'} justifyContent={'space-between'} alignItems={'flex-end'}>
          <Stack
            direction={'row'}
            alignItems={'center'}
            spacing={2}
            sx={{
              animation: 'open-opacity 0.3s ease',
            }}
          >
            {allImages.map((imgUrl, index) => {
              return (
                <CustomImage
                  className='product-item-hover__other-img'
                  wrapperClassName='product-item-hover__other-img-wrapper'
                  key={imgUrl}
                  src={imgUrl}
                  alt=''
                  onMouseEnter={() => onHoverImg(index)}
                />
              );
            })}
          </Stack>
        </Stack>
      </Box>
    </Box>
  );

  function test() {
    return (
      <Box className='product-item__hover-screen'>
        <Box
          mb={1.25}
          sx={{
            position: 'relative',
          }}
        >
          <CustomImage
            src={allImages[visibleImgIndex]}
            alt={item.name}
            className={`product-item__hover-screen-img ${item.imageMode}`}
            style={{
              aspectRatio: getAspectRatio(item.imageMode),
            }}
          />
        </Box>
        <Stack
          direction={'row'}
          spacing={0.75}
          className='product-item__other-images'
          mb={1}
          sx={{
            animation: 'open-opacity 0.3s ease',
          }}
        >
          {allImages.map((img, index) => {
            return (
              <CustomImage
                key={img}
                src={img}
                onMouseEnter={() => onHoverImg(index)}
                alt=''
                wrapperClassName='product-item__other-img-wrapper'
                style={{
                  aspectRatio: getAspectRatio(item.imageMode),
                }}
              />
            );
          })}
        </Stack>
        <Typography
          sx={{
            animation: 'open-opacity 0.3s ease',
          }}
          mb={'auto'}
          className='product-item__actual-price'
        >
          {numberService.insertSpaceAndFix(item.price, 2)} {item.currencyType === 'UZS' ? t('som') : t('ye')}
        </Typography>
        <BaseButton
          sx={{
            animation: 'open-opacity 0.3s ease',
          }}
          className='product-item__offer-btn'
          onClick={event => {
            event.stopPropagation();
            openChat();
          }}
        >
          <CommonSvg name={svgNames.Handshake} />
          {t('offerNow')}
        </BaseButton>
      </Box>
    );
  }
};

export default memo(NewProductItem);
