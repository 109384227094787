class NumberService {
  maxInt32 = 2147483647;
  maxInt64 = Number.MAX_SAFE_INTEGER;
  insertSpace(str: string | number){
    
    if(typeof str === 'number') str = str.toString();

    try {
      return str.replace(/(?!^)(?=(?:\d{3})+(?:\.|$))/gm, " ");
    } catch(err) {
      return str;
    }
  };
  insertSpaceAndFix(num: number | string, precision: number ) {
    return this.insertSpace(this.toFixedPrice(num, precision));
  }
  toFixedPrice (num: number | string, precision: number): (number | string) {
    if(isNaN(Number(num))) return num;

    return (+num).toFixed(precision).replace(/\.0+$/, '');
  };
  getPrice(num: number | string) {
    if(typeof num === 'string') {
      let org = num;

      num = Number(num);
      if(Number.isNaN(num)) return org;
    };

    let MinNum = num;
    let Text = '';
    if(num >= 1000) {
      MinNum = (num / 1000);
      Text = 'K';
    }
    if(num >= 1000000) {
      MinNum = (num / 1000000);
      Text = 'M';
    }

    return this.insertSpaceAndFix(MinNum, 2) + Text;
  };
  getRandomNumber(min: number, max: number) {
    return Math.floor(Math.random() * (max - min + 1) + min);
  };
  formatPhoneNumber(phoneNumber: string) {
    //   +998#########    ====>  +998## ### ## ##
    const cleanNum = phoneNumber.toString().replace(/\D/g, '');
    const match = cleanNum.match(/^(\d{5})(\d{0,3})(\d{0,2})(\d{0,2})$/);
    if (match) {
      return match[1] + ' ' + match[2] + ' ' + match[3] + ' ' + match[4];
    }
    return cleanNum;
  }
}

const numberService = new NumberService();


export default numberService;