import React from 'react';

type Props = {};

const TopSvg = (props: Props) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='17' height='16' viewBox='0 0 17 16' fill='none'>
      <path d='M9.83355 13.3333H7.16688V7.33332L4.83355 9.66665L3.22021 8.05332L8.50022 2.77332L13.7802 8.05332L12.1669 9.66665L9.83355 7.33332V13.3333Z' fill='white' />
    </svg>
  );
};

export default TopSvg;
