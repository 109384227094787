import React from 'react';

type Props = {};

const DiamondSvg = (props: Props) => {
  return (
    <svg xmlns='http://www.w3.org/2000/svg' width='17' height='16' viewBox='0 0 17 16' fill='none'>
      <path
        d='M1.99995 5.5H15M1.60683 5.88156L8.09902 14.3022C8.1462 14.3637 8.2069 14.4135 8.27643 14.4478C8.34595 14.4821 8.42243 14.4999 8.49995 14.4999C8.57748 14.4999 8.65396 14.4821 8.72348 14.4478C8.79301 14.4135 8.85371 14.3637 8.90089 14.3022L15.3931 5.88156C15.4569 5.79845 15.4939 5.69788 15.4992 5.59322C15.5045 5.48856 15.4779 5.38476 15.4228 5.29563L13.2206 1.74094C13.1752 1.66737 13.1119 1.60662 13.0364 1.56445C12.961 1.52228 12.8761 1.5001 12.7896 1.5H4.21027C4.12385 1.5001 4.0389 1.52228 3.96348 1.56445C3.88806 1.60662 3.82467 1.66737 3.77933 1.74094L1.57714 5.29563C1.52203 5.38476 1.49536 5.48856 1.50066 5.59322C1.50596 5.69788 1.54299 5.79845 1.60683 5.88156Z'
        stroke='white'
        strokeLinecap='round'
        strokeLinejoin='round'
      />
      <path d='M13 2L11.5 5.5M11.5 5.5L8.5 1.5L5.5 5.5M11.5 5.5L8.5 14L5.5 5.5M4 2L5.5 5.5' stroke='white' strokeLinecap='round' strokeLinejoin='round' />
    </svg>
  );
};

export default DiamondSvg;
