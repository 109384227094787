import { PRODUCT_STATE, ProductType } from '@/Models/Product/IProduct';
import { Box, Hidden, styled } from '@mui/material';
import Link from 'next/link';

const StyledProductItem = styled(Link)<{
  'data-item-type': ProductType | null;
  component?: React.FC<any>;
}>(({ theme, ...props }) => {
  const type = props['data-item-type'];
  const styles =
    type === ProductType.PREMIUM
      ? {
          color: '#ED0061',
          // border: '1px solid rgba(195, 17, 98, 0.50)',
          // boxShadow: '0px 4px 4px 0px rgba(256, 136, 198, 0.30)',
          // border: '1px solid rgba(0, 0, 0, 0.20)',
          boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.08)',
        }
      : type === ProductType.START
      ? {
          color: '#0C88C6',
          // border: '1px solid rgba(12, 136, 198, 0.50)',
          // boxShadow: '0px 4px 4px 0px rgba(12, 136, 198, 0.20)',
          // border: '1px solid rgba(0, 0, 0, 0.20)',
          boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.08)',
        }
      : {
          color: '#444',
          // border: '1px solid rgba(0, 0, 0, 0.20)',
          boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.08)',
        };

  return {
    // padding: '6px',
    borderRadius: '16px',
    position: 'relative',
    // border: styles.border,
    transition: 'box-shadow 0.6s ease',
    cursor: 'pointer',
    backgroundColor: '#fff',
    height: 'auto',

    //  MUI LINK komponent stillarini boxga o'xshatish uchun --------------------------------------------------------------------------------
    textDecoration: 'none',
    display: 'flex',
    flexDirection: 'column',
    color: 'inherit',
    //--------------------------------------------------------------------------------

    '.product-item': {
      '&__img-wrapper': {
        position: 'relative',
      },
      '&__text-wrapper': {
        padding: '12px 16px',
      },
      '&__price-wrapper': {
        padding: '10px 16px',
      },

      '&__img': {
        borderRadius: '16px',
        maxWidth: '100%',
        width: '100%',
        // height: 'auto',
        height: '296px',
        objectFit: 'cover',
      },

      '&__state': {
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        color: styles.color,
      },

      '&__name': {
        fontFamily: 'Inter',
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        wordBreak: 'break-word',
      },

      '&__location': {
        fontFamily: 'Inter',
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '16px',
        color: 'rgba(123, 126, 134, 1)',
        display: 'flex',
        justifyContent: 'space-between',
      },

      '&__location-name': {
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: '16px',
        color: 'rgba(123, 126, 134, 1)',
        display: '-webkit-box',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        WebkitLineClamp: 3,
        WebkitBoxOrient: 'vertical',
      },

      '&__old-price': {
        fontSize: '13px',
        fontFamily: "'Roboto', sans-serif",
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        textDecorationLine: 'line-through',
      },
      '&__actual-price': {
        fontSize: '16px',
        fontFamily: "'Roboto', sans-serif",
        color: '#000',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
      },

      '&__date': {
        fontSize: '12px',
        fontFamily: "'Roboto', sans-serif",
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        color: styles.color,
      },

      '&__like-btn': {
        position: 'absolute',
        top: '12px',
        right: '16px',
        padding: '0px',
        zIndex: '3',
      },

      '&__like-svg': {},

      '&__boost': {
        position: 'absolute',
        top: '25px',
        left: '16px',
        zIndex: '3',
        transform: 'translateY(-50%)',
        backgroundColor: styles.color,
        color: '#fff',
        borderRadius: '8px',
        padding: '4px 16px 4px 8px',
        minWidth: '83px',
      },
      '&__usedly': {
        position: 'absolute',
        bottom: '10px',
        left: '10px',
        zIndex: '3',
        transform: 'translateY(-50%)',
        color: '#fff',
        minWidth: '83px',
      },

      '&__boost-text': {
        fontFamily: 'Inter',
        fontSize: '12px',
        lineHeight: '14.52px',
        textDecoration: 'capitalize',
        marginLeft: '5px',
      },
      '&__usedly-text': {
        fontSize: '12px',
        lineHeight: 'normal',
        textDecoration: 'capitalize',
        padding: '4px 10px',
        borderRadius: '8px',
        fontWeight: 600,
      },

      '&__hover-screen': {
        display: 'none',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: '2',

        backgroundColor: '#fff',
        // padding: '6px',
        borderRadius: '16px',
        flexDirection: 'column',
      },

      '&__hover-screen-img': {
        borderRadius: '16px',
        maxWidth: '100%',
        width: '100%',
        height: '296px',
        objectFit: 'cover',
      },
      '&__hover-screen-text': {
        padding: '12px 16px',
      },
      '&__other-img-wrapper': {
        '&::after': {
          borderRadius: '6px',
          width: '100%',
          height: '32px',
        },
      },
      '&__other-images': {
        '& > *': {
          width: 'calc(100% / 5)',
        },
        img: {
          borderRadius: '6px',
          width: '100%',
          height: '32px',
          objectFit: 'cover',
        },
      },

      '&__offer-btn': {
        borderRadius: '42px',
        padding: '8px 10px',
        // marginBottom:'10px',
        fontSize: '14px',
        backgroundColor: styles.color,
        alignSelf: 'flex-start',
        border: '1px solid transparent',
        color: 'red',

        span: {
          display: 'flex',
          alignItems: 'center',
          color: '#fff',
          gap: '10px',
        },

        '&:hover': {
          backgroundColor: 'transparent',
          border: `1px solid ${styles.color}`,
          span: {
            color: styles.color,
          },
        },
        '&:focus': {
          boxShadow: `0 0 0 1px ${styles.color}`,
        },
      },
    },

    '&:hover': {
      boxShadow: styles.boxShadow,

      '.product-item__hover-screen': {
        display: 'flex',
      },
    },

    '@media(max-width: 991.98px)': {
      '.product-item': {
        '&__old-price': {
          fontSize: '12px',
        },
        '&__actual-price': {
          fontSize: '14px',
        },
        '&__date': {
          fontSize: '11px',
        },
        '&__boost': {
          // left: '3px',
        },
      },
    },
    '@media(max-width: 860px)': {
      '.product-item': {
        '&__img': {
          height: '400px',
        },
      },
    },
    '@media(max-width: 760.98px)': {
      '.product-item': {
        '&__img': {
          height: '300px',
        },
      },
    },
    '@media(max-width: 476.98px)': {
      height:'100% !important' ,

      '.product-item': {
        '&__img': {
          height: '240px',
        },
        '&__boost': {
          padding: '2px 5px !important',
        },
        '&__name': {
          display: '-webkit-box',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        WebkitLineClamp: 3,
        WebkitBoxOrient: 'vertical',
        },
        '&__like-btn': {
          top: '12px',
          right: '12px',
        },
        '&__like-svg': {
          width: '16px',
        },
      },
    },
    '@media(max-width: 320.98px)': {
      '.product-item': {
        '&__img': {
          height: '240px',
        },
        '&__boost': {
          left: '4px',
          padding: '2px 5px !important',
        },
        '&__boost-text': {
          padding: '0px !important',
          fontSize: '10px !important',
        },
        '&__like-btn': {
          top: '12px',
          right: '5px',
        },
        '&__like-svg': {
          width: '16px',
        },
      },
    },
  };
});

// ------------------------------------------------------------------------------------------------------------------------------------------

const StyledHorizontalProductItem = styled(Link)<{
  'data-item-type': ProductType | null;
  component?: React.FC<any>;
}>(({ theme, ...props }) => {
  const type = props['data-item-type'];
  const styles =
    type === ProductType.PREMIUM
      ? {
          color: '#ED0061',
          // border: '1px solid rgba(0, 0, 0, 0.20)',
          boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.08)',
        }
      : type === ProductType.START
      ? {
          color: '#0C88C6',
          // border: '1px solid rgba(0, 0, 0, 0.20)',
          boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.08)',
        }
      : {
          color: '#444',
          // border: '1px solid rgba(0, 0, 0, 0.20)',
          boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.08)',
        };

  return {
    borderRadius: '16px',
    position: 'relative',
    // border: styles.border,
    transition: 'box-shadow 0.6s ease',
    cursor: 'pointer',
    backgroundColor: '#fff',
    display: 'flex',
    alignItems: 'center',
    width: 'calc(50% - 8px)',

    //  MUI LINK komponent stillarini boxga o'xshatish uchun --------------------------------------------------------------------------------
    textDecoration: 'none',
    color: 'inherit',
    //--------------------------------------------------------------------------------

    '.product-item': {
      '&__main-img-wrapper': {
        // width: '10rem',
        height: '100%',
        borderRadius: '16px',
        overflow: 'hidden',
      },

      '&__img-wrapper': {
        position: 'relative',
        display: 'flex',
        alignItems: 'center',
        height: '100%',
      },
      '&__usedly-text': {
        fontSize: '12px',
        lineHeight: 'normal',
        color: '#fff',
        textDecoration: 'capitalize',
        padding: '4px 10px',
        borderRadius: '8px',
        fontWeight: 600,
      },

      '&__text-content-wrapper': {
        padding: '16px',
        width: '100%',
        alignSelf: 'stretch',
        height: 'auto',
        display: 'flex',
        flexDirection: 'column',
      },

      '&__img': {
        borderRadius: '16px',
        display: 'block',
        objectFit: 'cover',
        width: '10rem',
        height: '100%',
      },

      '&__state': {
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: 'normal',
        color: theme.customTheme.colors.primary,
      },

      '&__name': {
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
        fontFamily: "'Roboto', sans-serif",
        maxWidth: '400px',
        marginBottom: '10px',
        wordBreak: 'break-word',
        display: '-webkit-box',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
      },

      '&__description': {
        fontSize: '14px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        color: '#222',
        maxWidth: '600px',
        display: '-webkit-box',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        WebkitLineClamp: 2,
        WebkitBoxOrient: 'vertical',
        marginBottom: '8px',
      },

      '&__location': {
        fontSize: '12px',
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        color: '#818181',
        display: 'flex',
        justifyContent: 'space-between',
        marginBottom: '16px',
      },

      '&__price-wrapper': {
        flexDirection: 'row',
        justifyContent: 'flex-end',
        alignItems: 'center',
      },

      '&__actual-price': {
        fontSize: '20px',
        fontFamily: "'Roboto', sans-serif",
        color: '#000',
        fontStyle: 'normal',
        fontWeight: 600,
        lineHeight: 'normal',
      },

      '&__date': {
        fontSize: '16px',
        fontFamily: "'Roboto', sans-serif",
        fontStyle: 'normal',
        fontWeight: 400,
        lineHeight: 'normal',
        color: theme.customTheme.colors.primary,
      },

      '&__like-btn': {
        position: 'absolute',
        top: '6px',
        right: '6px',
        padding: '0px',
        zIndex: '3',
      },

      '&__like-svg': {},

      '&__boost': {
        zIndex: '3',
        backgroundColor: styles.color,
        color: '#fff',
        borderRadius: '8px',
        padding: '4px',
        width: '107px',
        marginBottom: '1rem',
      },

      '&__boost-text': {
        fontSize: '12px',
        lineHeight: 'normal',
        textDecoration: 'capitalize',
      },
    },

    '.product-item-hover': {
      display: 'none',
      position: 'absolute',
      top: 0,
      left: 0,
      right: 0,
      bottom: 0,
      zIndex: '2',

      backgroundColor: '#fff',
      // padding: '12px',
      borderRadius: '16px',

      '&__other-img-wrapper': {
        borderRadius: '6px',
        overflow: 'hidden',
      },

      '&__other-img': {
        width: '50px',
        height: '40px',
        borderRadius: '6px',
        display: 'block',
        objectFit: 'cover',
      },

      '&__actual-price': {
        fontSize: '20px',
        lineHeight: 'normal',
        fontWeight: 600,
        color: '#000',
        textAlign: 'right',
        paddingTop: '20px',
      },

      '&__offer-btn': {
        borderRadius: '42px',
        padding: '8px 10px',
        fontSize: '14px',
        backgroundColor: styles.color,
        border: '1px solid transparent',
        color: 'red',

        span: {
          display: 'flex',
          alignItems: 'center',
          color: '#fff',
          gap: '10px',
        },

        '&:hover': {
          backgroundColor: 'transparent',
          border: `1px solid ${styles.color}`,
          span: {
            color: styles.color,
          },
        },
        '&:focus': {
          boxShadow: `0 0 0 1px ${styles.color}`,
        },
      },
    },

    '&:hover': {
      boxShadow: styles.boxShadow,

      '.product-item-hover': {
        display: 'flex',
      },
    },

    '@media(max-width: 991.98px)': {
      width: '100%',
      '.product-item': {
        '&__state': {
          fontSize: '12px',
        },
        '&__price-wrapper': {
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'flex-start',
        },
        '&__old-price': {
          fontSize: '12px',
        },
        '&__actual-price': {
          fontSize: '14px',
        },
        '&__name': {
          fontSize: '14px',
          marginBottom: '4px',
        },
        '&__description': {
          WebkitLineClamp: 2,
          fontSize: '12px',
          marginBottom: '6px',
        },
        '&__location': {
          fontSize: '12px',
        },
        '&__date': {
          fontSize: '11px',
        },
      },
    },

    '@media(max-width: 767.98px)': {
      // gap: '10px',
      '.product-item__img': {
        // width: '8rem',
      },
      // '.product-item__main-img-wrapper': {
      //   width: '6rem',
      // },
    },
  };
});

export { StyledProductItem, StyledHorizontalProductItem };
